import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import { AccountInfoProvider } from 'src/components/AccountInfoProvider';
import { ConnectionLogDataProvider } from 'src/components/mainComponents/ConnectionLogDataContex/ConnectionLogDataContext';
import ThemeContextProvider from 'src/components/mainComponents/CustomColorChooser/ThemeContext';
import { NotificationProvider } from 'src/components/mainComponents/Notification';
import { ColorConfigurationLoaderProvider } from 'src/components/v2/ColorConfiguration';
import { ExtranetFeaturesProvider } from 'src/contexts/ExtranetFeaturesContext';
import i18n from 'src/translations/i18n';
import { AuthContextProvider } from 'src/user';

import { AppApolloProvider } from './components/AppApolloProvider';

export function Providers({ children }) {
  return (
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <AppApolloProvider>
          <AuthContextProvider>
            <AccountInfoProvider>
              <ExtranetFeaturesProvider>
                <ConnectionLogDataProvider>
                  <ThemeContextProvider>
                    <ColorConfigurationLoaderProvider>
                      <NotificationProvider>{children}</NotificationProvider>
                    </ColorConfigurationLoaderProvider>
                  </ThemeContextProvider>
                </ConnectionLogDataProvider>
              </ExtranetFeaturesProvider>
            </AccountInfoProvider>
          </AuthContextProvider>
        </AppApolloProvider>
      </I18nextProvider>
    </BrowserRouter>
  );
}
