import * as Toast from '@radix-ui/react-toast';
import { ReactNode } from 'react';

import './Notification.scss';

type NotificationProviderProps = {
  children: ReactNode;
};

export function NotificationProvider({ children }: NotificationProviderProps) {
  return (
    <Toast.Provider>
      {children}
      <Toast.Viewport className='Notification__viewport' />
    </Toast.Provider>
  );
}
